#webshopContainer {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: $primary-background-color;
    display: flex;
    flex-direction: column;
}
#topBar {
    height: 64px;
    width: 100%;
    overflow: hidden;
    background: $secondary-background-color;
    color: $secondary-text-color;
    @media (min-width: 768px) {
        height: 45px;
    }
    //box-shadow: 0 1px rgba(32,33,37,0.12);
}
.contentContainer {
    height: calc(100vh - 64px);
    padding: 10px 0 200px 0;
    @media (min-width: 768px) {
        padding: 10px 0 30px 100px;
        height: calc(100vh - 160px);
    }
    @media (min-width: 1024px) {
        padding: 10px 0 30px 120px;
    }
    @media (min-width: 1440px) {
        padding: 10px 0 30px 160px;
    }
    //height: 100%;
    width: 100%; 
    overflow: auto;
    overflow-x: hidden;
    //padding-bottom: 80px;
}
.contentContainer.searchVisible {
    height: calc(100vh - 190px);
    @media (min-width: 768px) {
        height: calc(100vh - 140px);
    }
}
.header {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 
    "categories";
    z-index: 100;
    @media (min-width: 768px) {
        grid-template-columns: 100px 200px 1fr 100px;
        grid-template-areas: ". logo categories .";
        grid-template-rows: 1fr;
    }
    @media (min-width: 1024px) {
        grid-template-columns: 120px 250px 1fr 100px;
    }
    @media (min-width: 1440px) {
        grid-template-columns: 160px 250px 1fr 180px;
    }       
    a {
        grid-area: logo;
        img {
            display: none;
            @media (min-width: 768px) {
                display: block;
                width: 100%;
                margin-left: 0px;
                margin-top: 10px;
            }
        }
    }
}
.footer {
    position: absolute;
    z-index: 800;
    padding: 8px;
    bottom: 0px;
    left: 0px;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    background: $secondary-background-color;
    color: $secondary-text-color;
    overflow: hidden;
    font-size: 0.6em;
    text-transform: uppercase;
    max-height: 30px;
    .left {
        width: 33%;
        text-align: left;
    }
    .mid {
        text-align: center;
    }
    .right {
        width: 33%;
        text-align: right;
        //display: inline-flex;
        display: flex;
        justify-content: flex-end;
    }
    a {
        color: $secondary-text-color;
        font-size: 0.9em;
        margin: auto 5px;
    }
}
.webShopFooter {

}